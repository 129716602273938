<template>
  <div class="notice">
    <!-- 通知详情页面 -->
    <Header
      back="返回"
      title="通知详情"
      index="首页"
      work_bench="工作台"
      beforeTitle="通知详情"
    />
    <!-- 通知详情内容 -->
    <div class="noticeText">
      <div
        class="textHead"
        v-loading="loading"
        element-loading-text="Loading···"
      >
        <h1>{{ noticeDetail.title }}</h1>
        <div class="textName">
          <span>发布人：{{ noticeDetail.writer }}</span>
          <span>发布时间：{{ noticeDetail.update_time }}</span>
        </div>
        <div class="textBody">
          <!-- <p class="bodyTitle"></p> -->
          <p>{{ noticeDetail.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(["noticeDetail"]),
  },
  created() {
    // console.log(this.$route.query.item);
    this.$store
      .dispatch("getNoticeDetail", { id: this.$route.query.id })
      .then((res) => {
        console.log(this.$route.query.id, res, this.$store.state.noticeDetail);
      });

    // this.axios
    //   .get("/api/member/view_self", {
    //     params: {
    //       token: this.token,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res, "个人信息");
    //     this.noticeName = res.data.true_name;
    //     this.noticeData = res.data.current_date;
    //   });
  },
  methods: {
    returnPage() {
      this.$router.go(-1);
    },
  },
  updated() {
    this.loading = false;
  },
  destroyed() {
    console.log("离开通知详情页清空内容");
    this.noticeDetail.title = "";
    this.noticeDetail.content = "";
    this.noticeDetail.update_time = "";
  },
};
</script>

<style scoped>
.notice {
  width: 100%;
  height: 100%;
}
.notice .noticeText {
  width: 95%;
  height: 100%;
  background: #fff;
  padding: 40px;
  margin-top: 15px;
}
.notice .noticeText .textHead h1 {
  font-size: 20px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
}
.notice .noticeText .textName span {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #a6abc7;
  display: inline-block;
  margin: 14px 90px 22px 0;
}
.notice .noticeText .textBody p {
  line-height: 30px;
}
</style>
